var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Dialog',{attrs:{"name":_vm.name,"title":_vm.title || '进程数据记录'},on:{"closed":_vm.closed}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('el-table',{attrs:{"data":_vm.data,"border":""}},[_c('el-table-column',{attrs:{"prop":"name","label":"员工","show-overflow-tooltip":"","align":"center"}}),_c('el-table-column',{attrs:{"prop":"Pname","label":"进程名","show-overflow-tooltip":"","align":"center"}}),_c('el-table-column',{attrs:{"prop":"AppDescription","label":"软件名","show-overflow-tooltip":"","align":"center"}}),_c('el-table-column',{attrs:{"prop":"FocusFormName","label":"窗口名","show-overflow-tooltip":"","align":"center"}}),_c('el-table-column',{attrs:{"label":"鼠标 点击/移动/键盘 输入速度","show-overflow-tooltip":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var MouseClickNum = ref_row.MouseClickNum;
var MouseMoveNum = ref_row.MouseMoveNum;
var KeysNum = ref_row.KeysNum;
return [_vm._v(" "+_vm._s(MouseClickNum)+"/"+_vm._s(MouseMoveNum)+"/"+_vm._s(KeysNum)+"次每分钟 ")]}}])}),_c('el-table-column',{attrs:{"prop":"Time","label":"时间","show-overflow-tooltip":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var Time = ref.row.Time;
return [_vm._v(_vm._s(Time.timeFormat("yyyy-MM-dd HH:mm")))]}}])})],1),_c('el-pagination',{attrs:{"current-page":_vm.pageData.pageIndex,"page-sizes":[20, 30, 40, 50],"page-size":_vm.pageData.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.pageData.totalNum},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }